import * as React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../utils/LinkResolver";
import { Layout } from "../components/Layout";
import Seo from "../components/Seo";
import { HomepageBanner } from "../components/HomepageBanner/HomepageBanner.js";
import { SliceZone } from "../components/SliceZone";

const HomeTemplate = ({ location, data }) => {
  if (!data) return null;
  const doc = data.prismicHomepage.data;
  const socialCards = data.prismicHomepage?.data?.body6;

  return (
    <Layout state={location.state} isHomepage={true}>
      <Seo
        title={doc?.meta_title || "Home"}
        description={doc?.meta_description || ""}
        socialCards={socialCards}
      />
      <HomepageBanner
        videoLink={doc.video_link}
        videoLinkHover={doc.video_link_hover}
        videoLinkMobile={doc.video_link_mobile}
      />
      <SliceZone location={location} sliceZone={doc.body} />
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    prismicHomepage {
      _previewable
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomepageDataBodyText
          ...HomepageDataBodyQuote
          ...HomepageDataBodyFullWidthImage
          ...HomepageDataBodyImageGallery
          ...HomepageDataBodyImageHighlight
          ...HomepageDataBodyPlaceholder
          ...HomepageDataBodyEmbedVideo
          ...HomepageDataBodySelectors
          ...HomepageDataBodyTextWithGallery
        }
        video_link {
          url
        }
        video_link_hover {
          url
        }
        video_link_mobile {
          url
        }
        meta_description
        meta_title
        body6 {
          ... on PrismicHomepageDataBody6GeneralCard {
            primary {
              image {
                url
              }
              description {
                text
              }
              title {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomepageDataBody6TwitterCard {
            primary {
              image {
                url
              }
              title {
                text
              }
              description {
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(HomeTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
