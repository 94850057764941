import * as React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../utils/LinkResolver";
import { Layout } from "../components/Layout";
import Seo from "../components/Seo";
import { SliceZone } from "../components/SliceZone";

const PageTemplate = ({ location, data }) => {
  if (!data) return null;
  const page = data.prismicPage;

  const capitalizeFirstLetter = (input) => {
    return input[0].toUpperCase() + input.slice(1);
  };
  const url = location.pathname;
  const socialCards = data.prismicPage?.data?.body6;

  return (
    <Layout state={location.state}>
      {/* <Seo title={page.data.document_display_name.text} /> */}
      <Seo
        title={page.data?.meta_title || capitalizeFirstLetter(page.uid)}
        description={page?.data.meta_description || ""}
        pageUrl={url}
        socialCards={socialCards}
      />
      <SliceZone location={location} sliceZone={page.data.body} />
    </Layout>
  );
};

export const query = graphql`
  query PageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyText
          ...PageDataBodyText1
        }
        meta_description
        meta_title
        body6 {
          ... on PrismicPageDataBody6GeneralCard {
            id
            primary {
              title {
                text
              }
              image {
                url
              }
              description {
                text
              }
            }
          }
          ... on PrismicPageDataBody6TwitterCard {
            id
            primary {
              title {
                text
              }
              image {
                url
              }
              description {
                text
              }
            }
          }
        }
      }
      uid
    }
  }
`;

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
