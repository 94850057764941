// HomepageBanner.js file

import React, { useState, useRef } from "react";
import IsInView from "../../utils/IsInView";

import {
  sectionHomepageBanner,
  wrapperHomepageBanner,
  wrapperVideo,
  wrapperVideoOpen,
  wrapperMarquee,
  marqueeTrack,
  marqueeContent,
  videoDesktop,
  videoMobile,
  videoClass
} from "./HomepageBanner.module.scss";

export const HomepageBanner = ({
  videoLink,
  videoLinkHover,
  videoLinkMobile,
}) => {
  const [hoverVideoActive, setHoverVideoActive] = useState(false);
  const hoverVideo = useRef(null);

  const clickedWrapper = (el) => {
    if (el?.target.classList.contains("front-video")) {
      el.preventDefault();
      return null;
    }

    if (!hoverVideoActive === false) {
      hoverVideo.current.pause();
    } else {
      hoverVideo.current.play();
    }
    setHoverVideoActive(!hoverVideoActive);
  };

  return (
    <IsInView
      callback={(isIntersecting) => {
        if (!isIntersecting) {
          setHoverVideoActive(false);
          if (hoverVideo.current) {
            hoverVideo.current.pause();
          }
        }
      }}
    >
      <section
        className={`typo__section ${sectionHomepageBanner}`}
        role="button"
        tabIndex={0}
        style={
          hoverVideoActive
            ? {
                cursor: "default",
                zIndex: "1000",
              }
            : null
        }
      >
        <div className={wrapperHomepageBanner}>
          <div
            className={`${wrapperVideo} ${
              hoverVideoActive ? wrapperVideoOpen : ""
            }`}
            onClick={clickedWrapper}
            onKeyDown={clickedWrapper}
            style={
              hoverVideoActive
                ? {
                    backgroundColor: "rgba(112, 6, 80, 0.8)",
                    height: "calc(100% + 30px)",
                  }
                : null
            }
          >
            <video
              className={`${videoLinkMobile?.url ? videoDesktop : ""} ${videoClass}`}
              autoPlay
              muted
              playsInline
              loop
              src={videoLink.url}
            >
              <source src={videoLink.url}></source>
              Sorry, your browser doesn't support embedded videos.
            </video>
            {videoLinkMobile?.url ? (
              <video
                className={`${videoMobile} ${videoClass}`}
                autoPlay
                muted
                playsInline
                loop
                src={videoLinkMobile.url}
              >
                <source src={videoLinkMobile.url}></source>
                Sorry, your browser doesn't support embedded videos.
              </video>
            ) : null}
            <video
              autoPlay
              muted={!hoverVideoActive}
              volume={0}
              ref={hoverVideo}
              style={{ visibility: hoverVideoActive ? "visible" : "hidden" }}
              src={videoLinkHover.url}
              controls={true}
              playsInline
              className="front-video"
              onClick={(e) =>
                {if( !(/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)))) {
                  e.target.paused ? e.target.play() : e.target.pause()
                }}
              }
            >
              <source src={videoLinkHover.url}></source>
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </div>
      </section>
      <div className={wrapperMarquee}>
        <div className={marqueeTrack}>
          <div className={marqueeContent}>
            &nbsp;YOU CAN ONLY CHANGE THE GAME WHEN YOU KNOW WHAT THE BENCHMARK
            IS. ↓&nbsp;&nbsp;YOU CAN ONLY CHANGE THE GAME WHEN YOU KNOW WHAT THE
            BENCHMARK IS. ↓&nbsp;&nbsp;YOU CAN ONLY CHANGE THE GAME WHEN YOU
            KNOW WHAT THE BENCHMARK IS. ↓&nbsp;&nbsp;YOU CAN ONLY CHANGE THE
            GAME WHEN YOU KNOW WHAT THE BENCHMARK IS. ↓&nbsp;&nbsp;YOU CAN ONLY
            CHANGE THE GAME WHEN YOU KNOW WHAT THE BENCHMARK IS.
            ↓&nbsp;&nbsp;YOU CAN ONLY CHANGE THE GAME WHEN YOU KNOW WHAT THE
            BENCHMARK IS. ↓&nbsp;&nbsp;YOU CAN ONLY CHANGE THE GAME WHEN YOU
            KNOW WHAT THE BENCHMARK IS. ↓&nbsp;&nbsp;YOU CAN ONLY CHANGE THE
            GAME WHEN YOU KNOW WHAT THE BENCHMARK IS. ↓&nbsp;&nbsp;YOU CAN ONLY
            CHANGE THE GAME WHEN YOU KNOW WHAT THE BENCHMARK IS.
            ↓&nbsp;&nbsp;YOU CAN ONLY CHANGE THE GAME WHEN YOU KNOW WHAT THE
            BENCHMARK IS. ↓&nbsp;
          </div>
        </div>
      </div>
    </IsInView>
  );
};
